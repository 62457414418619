import {Link} from 'react-router-dom'
import MetaTags from 'react-meta-tags'

import {motion} from 'framer-motion'

import { ReactSVG as SVG } from 'react-svg'
import linkedin_icon from './../../Icons/linkedin.svg'
import instagram_icon from './../../Icons/instagram.svg'
import envelope from './../../Images/envelope.png'
import './Contact.css'

function Contact() {

	const contact_name = 'hello'
	const contact_domain = 'quentinsedmi'
	const contact_tld = 'com'
	const contact_number_center = ' 64 4'
	const contact_number_indicator = '+33 '
	const contact_number_end = '6 17'
	const contact_number_start = '6 01'
	const instagram_link = 'https://www.instagram.com/quentinsedmi'
	const linkedin_link = 'https://www.linkedin.com/in/quentinsedmi'

	const transition = {duration: .6, ease: [.43, .13, .23, .96]}

	return (<>
		<MetaTags>
			<title>Contact - Quentin Sedmi</title>
			<meta name='description' content='Write Quentin Sedmi a message or contact him by phone. Follow him on social networks using @quentinsedmi (Instagram, LinkedIn...)' />
		</MetaTags>
		<header className='header'>
			<Link to="/">
				<div className='header_title header_title_contact'>
					<motion.div
						initial={ { opacity: 1, y: 0 } }
						animate={ { opacity: 0, y: -20 } }
						exit={ { opacity: 1, y: 0 } }
						transition={ { delay: .3, ...transition } }
						className='header_name header_name_contact'
					>
						<div className='book'>Quentin&nbsp;Sedmi&nbsp;<span className='header_pronouns regular'>he/him</span></div>
					</motion.div>
					<motion.div
							initial={ { opacity: 0, y: 20 } }
							animate={ { opacity: 1, y: 0 } }
							exit={ { opacity: 0, y: 20 } }
							transition={ { delay: .3, ...transition } }
							className='header_name header_name_contact'
					>
						<div className='book'>@quentinsedmi&nbsp;<span className='header_pronouns regular'>he/him</span></div>
					</motion.div>
				</div>
			</Link>
			<motion.div
				className='header_link link'
				initial={{
					opacity: 0,
					transition: transition
				}}
				animate={{
					opacity: 1,
					transition: {...transition, delay: 1.2}
				}}
				exit={{
					opacity: 0,
					transition: transition
				}}
			>
				<Link to="/about">About</Link>
			</motion.div>
		</header>
		<motion.div
			className='contact'
			initial={{opacity: 0}}
			animate={{opacity: 1, transition: {...transition, delay: 1.8}}}
			exit={{opacity: 0}}
		>
			<div>
				<img className='hero_icon' src={envelope} alt='Back of a white envelope'/>			
				<div className='email_address title'
					contact-name={contact_name}
					contact-domain={contact_domain}
					contact-tld={contact_tld}
					onClick={(e) => {
						e.preventDefault()
						window.open('mailto:' + e.currentTarget.attributes.getNamedItem('contact-name').value + '@' + e.currentTarget.attributes.getNamedItem('contact-domain').value + '.' + e.currentTarget.attributes.getNamedItem('contact-tld').value, '_self')
					}}>
				</div>
				<p className='phone_number'
					contact-number-center={contact_number_center}
					contact-number-indicator={contact_number_indicator}
					contact-number-end={contact_number_end}
					contact-number-start={contact_number_start}
					onClick={(e) => {
						e.preventDefault()
						window.open('tel:' + e.currentTarget.attributes.getNamedItem('contact-number-indicator').value + e.currentTarget.attributes.getNamedItem('contact-number-start').value + e.currentTarget.attributes.getNamedItem('contact-number-center').value + e.currentTarget.attributes.getNamedItem('contact-number-end').value, '_self')
					}}><span>Send me a message or call me directly </span>
				</p>
				<div className='social_links'>
					<a href={linkedin_link} target='_blank' rel='noreferrer'>
						<div className='link_icon linkedin_link'>
							<SVG className='icon space_between' src={linkedin_icon} alt={'LinkedIn'}/>
							{/* <span>LinkedIn</span> */}
						</div>
					</a>
					{/* <a href={instagram_link} target='_blank' rel='noreferrer'>
						<div className='link_icon instagram_link'><SVG className='icon space_between' src={instagram_icon} renumerateIRIElements={false}/><span>Instagram</span></div>
					</a> */}
				</div>
			</div>
		</motion.div>
	</>);
}

export default Contact
