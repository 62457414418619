import { useState } from 'react'
import {Link} from 'react-router-dom'
import MetaTags from 'react-meta-tags'

import {motion, AnimatePresence} from 'framer-motion'

import { ReactSVG as SVG } from 'react-svg'
import chevron_right from './../../Icons/chevron_right.svg'
// Images
import briefcase from './../../Images/briefcase.png'
import brain_left from './../../Images/brain_left.png'
import brain_right from './../../Images/brain_right.png'
import './Home.css'

const transition = {duration: .6, ease: [.43, .13, .23, .96]}
const spring = { type: 'spring', stiffness: 700, damping: 30}

const ifm_link = 'https://www.ifmparis.fr/en'
const dior_link = 'https://www.dior.com'
function Home(props) {

	// Toggle Button state
	const [isRight, setIsRight] = useState(true)

	return (<>
		<MetaTags>
			<title>Quentin Sedmi</title>
			<meta name='description' content='Quentin Sedmi is a digital designer, engineer and creative coder specialized in Digital Marketing and Communication. Future student of the Specialized Master in Fashion and Luxury Management.' />
		</MetaTags>
		<header className='header'>
			<Link to="/">
				<div className='header_title'>
					<div className='header_name'>
						<div className='book'>Quentin Sedmi&nbsp;<span className='header_pronouns regular'>he/him</span>
						</div>
					</div>
				</div>
			</Link>
			<motion.div
				className='header_link link'
				initial={{
					opacity: 0,
					transition: transition
				}}
				animate={{
					opacity: 1,
					transition: {...transition, delay: .6}
				}}
				exit={{
					opacity: 0,
					transition: transition
				}}
			>
				<Link to="/about">About</Link>
			</motion.div>
		</header>
		<motion.div
				className='home'
				initial={{opacity: 0}}
				animate={{opacity: 1, transition: {...transition, delay: 1.2}}}
				exit={{opacity: 0}}
				transition={transition}
		>
			<AnimatePresence>
			{props.homeMessageVisibility && <motion.div
			exit={{
				height: 0,
				paddingBottom: 0,
				paddingTop: 0,
				opacity: 0,
				transition: { delay: .6, ...transition }
			}}>
				<motion.div
					exit={{
						opacity: 0,
						transition: transition
					}}
					className='message'
				>
					<img className='hero_icon' src={briefcase} alt='Brown case with a handle on top, and a silver colored clasp on the front'/>
					<div className='message_title title'>Open to Work!</div>
					<p className='message_description'> I'm a Fashion and Luxury Manager with more than 2 years of&nbsp;experiences in&nbsp;the&nbsp;industry.<br/>I'm&nbsp;currently looking for a role related to product development, image and brand identity.</p>
					<div className='message_actions'>
						{/* <div className='message_close' onClick={() => props.setHomeMessageVisibility(false)}>Close</div> */}
						<div className='message_open'>
							{/* <div className='link_icon hover_underline'><a href={dior_link} target='_blank' rel="noreferrer"><span>Go to website</span><SVG className='icon' src={chevron_right}/></a></div> */}
							<div className='link_icon hover_underline book'>
								<Link to="/contact">
									<span>Contact me</span>
									<SVG className='icon' src={chevron_right}/>
								</Link>
							</div>
						</div>
					</div>
				</motion.div>
			</motion.div>}
			</AnimatePresence>
			<ToggleButton isRight={isRight} setIsRight={setIsRight}/>
			
			<div className='home_title medium'>
				<AnimatePresence>
					{!isRight && <motion.div
						initial={{
							opacity: 0,
							x: -150,
							translateX: '-50%'
						}}
						animate={{
							opacity: 1,
							x: 0,
							transition: spring
						}}
						exit={{
							opacity: 0,
							x: -150,
							transition: spring
						}}
						className='engineer_description'
					>
						Engineer<br/>
						<span className='italic regular'>with artistic<br/>
						sensibility</span>
					</motion.div>}
				</AnimatePresence>
				<AnimatePresence initial={false}>
					{isRight && <motion.div className='artist_description'
						style={{
							translateX: '-50%'
						}}
						initial={{
							opacity: 0,
							x: 150,
							translateX: '-50%'
						}}
						animate={{
							opacity: 1,
							x: 0,
							transition: spring
						}}
						exit={{
							opacity: 0,
							x: 150,
							transition: spring
						}}
					>
						<span className='italic regular'>Artist</span><br/>
						with technical<br/>
						skills
					</motion.div>}
				</AnimatePresence>
			</div>
		</motion.div>
		<div className='footer'>
			<div className='header_link link'><Link to="/contact">Contact</Link></div>
		</div>
	</>)
}

// Toggle Button
function ToggleButton({isRight, setIsRight}) {
	return (
		<div className='toggle_brain' data-is-right={isRight} onClick={() => setIsRight(!isRight)}>
			<div className='toggle_text'>Right</div>
			<div className='toggle_handle_background'></div>
			<motion.div className='toggle_brain_handle' layout transition={spring}>
				<img style={{display: isRight ? 'block': 'none'}} src={brain_right} alt='Pink brain from the right side'/>
				<img style={{display: isRight ? 'none': 'block'}} src={brain_left} alt='Pink brain from the left side'/>
			</motion.div>
			<div className='toggle_shadow'></div>
			<div className='toggle_text'>Left</div>
		</div>
	)
}

export default Home
