import { useState } from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import {AnimatePresence} from 'framer-motion'
import useMediaQuery from './Hooks/useMediaQuery'
// Pages
import Home from './Pages/Home/Home'
import About from './Pages/About/About'
import Contact from './Pages/Contact/Contact'
import NotFound from './Pages/NotFound/NotFound'

import './App.css'

function App() {

	// Media queries
	const isSmall = useMediaQuery('(min-width: 24rem)')
	const isMedium = useMediaQuery('(min-width: 28rem)')
	const isLarge = useMediaQuery('(min-width: 46.8rem)')
	const isFull = useMediaQuery('(min-width: 73rem)')

	// Visibility of home message
	const [homeMessageVisibility, setHomeMessageVisibility] = useState(true)
	const [aboutPictureOpened, setAboutPictureOpened] = useState(false)	

	return (
		<Router>
			<Route
				render={({ location }) => (
				<AnimatePresence initial={false} exitBeforeEnter>
					<Switch location={location} key={location.pathname}>
					<Route
						exact
						path='/'
						render={() => <Home homeMessageVisibility={homeMessageVisibility} setHomeMessageVisibility={setHomeMessageVisibility} />}
					/>
					<Route
						exact
						path='/about'
						render={() => <About aboutPictureOpened={aboutPictureOpened} setAboutPictureOpened={setAboutPictureOpened} mediaQuery={{isSmall, isMedium, isLarge, isFull}} />}
					/>
					<Route
						exact
						path='/contact'
						render={() => <Contact />}
					/>
					<Route render={() => <NotFound />} />
					</Switch>
				</AnimatePresence>
				)}
			/>
		</Router>
	);
	
}

export default App

        /* <a
          className='Link'
          href='https://reactjs.org'
          target='_blank'
          rel='noopener noreferrer'
        >
          Learn React
        </a>
		
		
		
		return (
		<Router>
			<AnimatePresence initial={false} exitBeforeEnter>
				<Switch>
					<Route
						exact
						path='/'
						render={() => <><Header/><Home/></>}
					/>
					<Route
						exact
						path='/about'
						render={() => <><Header/><About/></>}
					/>
					<Route
						exact
						path='/contact'
						render={() => <><Header/><Contact/></>}
					/>
				</Switch>
			</AnimatePresence>
			<div className='footer'>
				<div className='header_link link'><Link to="/contact">Contact</Link></div>
			</div>
		</Router>
	);*/
