import {Link} from 'react-router-dom'
import MetaTags from 'react-meta-tags'

import {motion, AnimatePresence} from 'framer-motion'

import pleading_face from './../../Images/pleading_face.png'
import index_left from './../../Images/index_left.png'
import index_right from './../../Images/index_right.png'
import './NotFound.css'

const transition = {duration: .6, ease: [.43, .13, .23, .96]}

function NotFound() {

	return (<>
		<MetaTags>
			<title>Quentin Sedmi</title>
			<meta name='description' content='Quentin Sedmi is a digital designer, engineer and creative coder specialized in Digital Marketing and Communication. Future student of the Specialized Master in Fashion and Luxury Management.' />
		</MetaTags>
		<header className='header'>
			<Link to="/">
				<div className='header_title'>
					<div className='header_name'>
						<div className='book'>Quentin Sedmi&nbsp;<span className='header_pronouns regular'>he/him</span>
						</div>
					</div>
				</div>
			</Link>
			<motion.div
				className='header_link link'
				initial={{
					opacity: 0,
					transition: transition
				}}
				animate={{
					opacity: 1,
					transition: {...transition, delay: .6}
				}}
				exit={{
					opacity: 0,
					transition: transition
				}}
			>
				<Link to="/about">About</Link>
			</motion.div>
		</header>
		<motion.div
				className='not_found'
				initial={{opacity: 0}}
				animate={{opacity: 1, transition: {...transition, delay: 1.2}}}
				exit={{opacity: 0}}
				transition={transition}
		>
			<div className='not_found_title medium'>404
				<p className='regular'>Sorry, that page can't be found.</p>
				<img className='hero_icon pleading_face' src={pleading_face} alt='Shy and pleading face with glossy eyes'/>	
				<div className='indexes'>
				<AnimatePresence initial={true}>
					<motion.img
						animate={{x: ['0rem', '-.625rem', '0rem', '-.4rem', '0rem']}}
						transition={{type: 'spring', duration: 1.2, repeatDelay: 2, repeat: Infinity}}
						className='index_right'
						key='index_right'
						src={index_right}
						alt='Backhand index pointing right'
					/>
					<motion.img
						animate={{x: ['0rem', '.625rem', '0rem', '.4rem', '0rem']}}
						transition={{type: 'spring', duration: 1.2, repeatDelay: 2, repeat: Infinity}}
						className='index_left'
						key='index_left'
						src={index_left}
						alt='Backhand index pointing left'
					/>	
				</AnimatePresence>
				</div>
			</div>
			
		</motion.div>
		<div className='footer'>
			<div className='header_link link'><Link to="/contact">Contact</Link></div>
		</div>
	</>);
}

export default NotFound
