import { useState } from 'react'
import {  Carousel as CarouselOld } from '@trendyol-js/react-carousel'
import Carousel from './../../Components/Carousel/Carousel'

import {Link} from 'react-router-dom'
import MetaTags from 'react-meta-tags'

import {motion, AnimatePresence} from 'framer-motion'

// Softwares icons
import { ReactSVG as SVG } from 'react-svg'
import download_icon from './../../Icons/download.svg'
import close_icon from './../../Icons/close.svg'
import arrow_right from './../../Icons/arrow_right.svg'
import photoshop from './../../Icons/photoshop.svg'
import illustrator from './../../Icons/illustrator.svg'
import after_effects from './../../Icons/after_effects.svg'
import indesign from './../../Icons/indesign.svg'
import premiere_pro from './../../Icons/premiere_pro.svg'
import terminal from './../../Icons/terminal.png'
import vs_code from './../../Icons/vs_code.png'
import outlook from './../../Icons/outlook.png'
import excel from './../../Icons/excel.png'
import powerpoint from './../../Icons/powerpoint.png'

// Profile picture thumbnail
import profile_picture from './../../Images/profile_picture/profile_picture.jpg'
import profile_picture_200 from './../../Images/profile_picture/profile_picture_200.jpg'
import profile_picture_620 from './../../Images/profile_picture/profile_picture_620.jpg'
import profile_picture_876 from './../../Images/profile_picture/profile_picture_876.jpg'
import profile_picture_1123 from './../../Images/profile_picture/profile_picture_1123.jpg'
import profile_picture_1336 from './../../Images/profile_picture/profile_picture_1336.jpg'
import profile_picture_1400 from './../../Images/profile_picture/profile_picture_1400.jpg'

// Images
import briefcase from './../../Images/briefcase.png'
import './About.css'

// Softwares
const softwares = {
	photoshop: {name: 'Photoshop', file: photoshop},
	illustrator: {name: 'Illustrator', file: illustrator},
	after_effects: {name: 'After Effects', file: after_effects},
	indesign: {name: 'Indesign', file: indesign},
	premiere_pro: {name: 'Premiere Pro', file: premiere_pro},
	terminal: {name: 'Terminal', file: terminal},
	vs_code: {name: 'Visual Studio Code', file: vs_code},
	outlook: {name: 'Outlook', file: outlook},
	excel: {name: 'Excel', file: excel},
	powerpoint: {name: 'PowerPoint', file: powerpoint}
}

const tween = { type: 'tween', duration: .6, ease: [.44, .14, .24, .96]}
const spring = { type: 'spring', stiffness: 700, damping: 30}


// Icon button variants
const iconButtonVariants = {
	hover: { scale: 1.1 },
	pressed: { scale: 0.95 }
}

// Profile thumbnail sizes
const pictureSizes = {
	opened : { isFull: { outerCircle: 44, innerCircle: 40 }, isNotFull: { outerCircle: 36, innerCircle: 36 } },
	closed : { isFull: { outerCircle: 52, innerCircle: 48 }, isNotFull: { outerCircle: 44, innerCircle: 40 } }
}

// Profile thumbnail
function profileThumbnailVariants(sizes) {
	return {
	circle: {
		initial: { width: 0, height: 0, marginRight: '0px' },
		animate: { width: sizes.outerCircle, height: sizes.outerCircle, marginRight: '10px', transition: { ...tween, delay: .6 } },
		exit: { width: 0, height: 0, marginRight: '0px', transition: { ...tween, delay: .6 } }
	},
	outerCircle: {
		initial: { width: 0, height: 0 },
		animate: { width: sizes.outerCircle, height: sizes.outerCircle, transition: { ...spring, delay: 1.8 } },
		exit: { width: 0, height: 0, transition: tween }
	},
	innerCircle: {
		initial: { opacity: 0, width: 0, height: 0 },
		animate: { width: sizes.innerCircle, height: sizes.innerCircle, opacity: 1, transition: { ...tween, delay: .6, duration: 1.2 } },
		exit: { opacity: 0, width: 0, height: 0, transition: { ...tween, delay: 0.6 } }
	}}
}

function About({mediaQuery, aboutPictureOpened, setAboutPictureOpened}) {

	// State
	const [isFullscreen, setFullscreen] = useState(false)

	// Handle fullscreen
	function handleFullscreen() {
		setFullscreen(!isFullscreen)
		const body = document.body.classList
		body.contains('fullscreen') ? body.remove('fullscreen') : body.add('fullscreen')
	}

	// Carousel right arrow
	const rightArrow = <motion.div
		variants={iconButtonVariants}
		whileHover='hover'
		whileTap='pressed'
	>
		<SVG className='icon' src={arrow_right}/>
	</motion.div>

	// Links
	const ifm_link = 'https://www.ifmparis.fr/en'

	// Return
	return (<>

		<MetaTags>
			<title>About - Quentin Sedmi</title>
			<meta name='description' content='Future student in management of creative industries (Fashion, Luxury, Beauty...), Quentin Sedmi is a creative engineer specialized in Image, Multimedia, Audiovisual and Communication.' />
		</MetaTags>

		{/* Fullscreen */}
		<Fullscreen isFullscreen={isFullscreen} handleFullscreen={handleFullscreen} mediaQuery={mediaQuery}/>
		
		{/* Header */}
		<header className='header'>
			<div>
			<motion.div className='header_picture_circle' key={mediaQuery.isFull}
				onClick={() => { handleFullscreen(); !aboutPictureOpened && setAboutPictureOpened(true) }}
				variants={profileThumbnailVariants(aboutPictureOpened ? (mediaQuery.isFull ? pictureSizes.opened.isFull : pictureSizes.opened.isNotFull) : (mediaQuery.isFull ? pictureSizes.closed.isFull : pictureSizes.closed.isNotFull)).circle}
				initial='initial'
				animate='animate'
				exit='exit'
				whileHover={{ scale: 1.1 }}
				whileTap={{ scale: 0.95 }}
			>
				<motion.div className='header_picture_outercircle'
					style={{display: aboutPictureOpened ? 'none' : 'flex'}}
					variants={profileThumbnailVariants(aboutPictureOpened ? (mediaQuery.isFull ? pictureSizes.opened.isFull : pictureSizes.opened.isNotFull) : (mediaQuery.isFull ? pictureSizes.closed.isFull : pictureSizes.closed.isNotFull)).outerCircle}
				>
					<AnimatePresence>
						<motion.div 
							initial={{ rotate: 0 }}
							animate={{ rotate: 1080, transition: { ...tween, delay: 1.2, repeatDelay: 1.2, repeat: Infinity, duration: 3.6 } }}
						></motion.div>
					</AnimatePresence>
				</motion.div>
				<motion.div className='header_picture_innercircle'
					variants={profileThumbnailVariants(aboutPictureOpened ? (mediaQuery.isFull ? pictureSizes.opened.isFull : pictureSizes.opened.isNotFull) : (mediaQuery.isFull ? pictureSizes.closed.isFull : pictureSizes.closed.isNotFull)).innerCircle}
				>
					<motion.img
						className='header_picture'
						src={profile_picture}
						alt='Profile picture of Quentin Sedmi'
						initial={{ opacity: 0, width: 0,height: 0 }}
						animate={{ opacity: 1, width: mediaQuery.isFull ? 44 : 36, height: mediaQuery.isFull ? 44 : 36, transition: { ...tween, delay: .6 } }}
						exit={{ opacity: 0, width: 0, height: 0, transition: { ...tween, delay: .6} }}
					/>
				</motion.div>
			</motion.div>
			<Link to="/">
				<div className='header_title'>
					<div className='header_name'>
						<div className='book'>Quentin Sedmi&nbsp;<span className='header_pronouns regular'>he/him</span></div>
					</div>
				</div>
			</Link>
			</div>
			<motion.div
				className='header_link link'
				style={{opacity: 0}}
				animate={{ opacity: 1, transition: {...tween, delay: 1.2} }}
				exit={{ opacity: 0, transition: tween }}
			>
				<Link to="/contact">Contact</Link>
			</motion.div>
		</header>
		
		{/* About */}
		<motion.div 
			className='about'
			initial={{opacity: 0}}
			animate={{opacity: 1, transition: {...tween, delay: 1.8}}}
			exit={{opacity: 0}}
			transition={tween}
		>
			<div className='about_section about_introduction'>
				<h1 className='about_title title'>Hi!</h1>
				<p className='about_paragraph'>I just graduated from the Specialised Master in Fashion and Luxury Management at&nbsp;<a href={ifm_link} className='underline' target='_blank' rel="noreferrer">Institut Français de la Mode</a>.</p>
				<p className='about_paragraph'>I’m a creative engineer specialized in&nbsp;Image, Multimedia, Audiovisual and&nbsp;Communication.</p>
				<p className='about_paragraph'>I’m based in Paris.</p>
				<a href={process.env.PUBLIC_URL + '/files/CV Quentin Sedmi.pdf'} target="_blank" rel="noopener noreferrer">
					<motion.div className='link_icon download_CV book'><SVG className='icon space_between' src={download_icon}/><span>Download the CV</span></motion.div>
				</a>
			</div>
		</motion.div>
	</>)
}

// Fullscreen
function Fullscreen({isFullscreen, handleFullscreen}) {

	return (
		<motion.div 
			className='picture_fullscreen'
			data-is-fullscreen={isFullscreen}
			exit={() => {isFullscreen && handleFullscreen(); document.body.classList.remove('fullscreen')}}
		>
			<div className='picture_actionbar'>
				{isFullscreen && <motion.div
					variants={iconButtonVariants}
					whileHover='hover'
					whileTap='pressed'
				>
					<div className='picture_close' onClick={ () => handleFullscreen() }>
						<SVG className='icon space_between' src={close_icon}/>
					</div>
				</motion.div>}
			</div>

			<picture data-is-fullscreen={isFullscreen}>
				<motion.img
					layout
					transition={ isFullscreen ? {type: 'spring', duration: .3} : {...spring, duration: .3}}
					sizes='(max-width: 1400px) 100vw, 1400px'
					srcSet={ profile_picture_200 + ' 200w, ' + profile_picture_620 + ' 620w, ' + profile_picture_876 + ' 876w, ' + profile_picture_1123 + ' 1123w, ' + profile_picture_1336 + ' 1336w, ' + profile_picture_1400 + ' 1400w ' }
					src={profile_picture_1400}
					alt='Profile picture of Quentin Sedmi'
				/>
			</picture>
		</motion.div>)
}

export default About

{/* <div className='about_section about_skills'>
				<motion.div>
					<CarouselOld
						className='about_softwares'
						show={mediaQuery.isLarge ? 6 : mediaQuery.isMedium ? 5 : mediaQuery.isSmall ? 4 : 3}
						leftArrow={''}
						rightArrow={rightArrow}
						swiping={true}
						autoPlay={true}
						responsive={true}
					>
						{Object.keys(softwares).map((software) => {
							switch(softwares[software].file.split('.').pop()) {
								case 'svg': 
									return <div className='about_software' key={softwares[software].file}><SVG src={softwares[software].file} aria-label={'Icon of the ' + softwares[software].name + ' Software'}/></div>
								default:
									return <div className='about_software'  key={softwares[software].file}><img src={softwares[software].file} alt={'Icon of the ' + softwares[software].name + ' Software'}/></div>
							}
						})}
					</CarouselOld>

					<Carousel
						show={mediaQuery.isLarge ? 6 : mediaQuery.isMedium ? 5 : mediaQuery.isSmall ? 4 : 3}
						mediaQuery={mediaQuery}
					>
						{Object.keys(softwares).map((software) => {
							switch(softwares[software].file.split('.').pop()) {
								case 'svg': 
									return <div className='about_software' key={softwares[software].file}><SVG src={softwares[software].file} aria-label={'Icon of the ' + softwares[software].name + ' Software'}/></div>
								default:
									return <div className='about_software'  key={softwares[software].file}><img src={softwares[software].file} alt={'Icon of the ' + softwares[software].name + ' Software'}/></div>
							}
						})}
					</Carousel>
				</motion.div>
				<p className='about_paragraph title'>I use graphic design, video editing, web development, marketing, photography and&nbsp;productivity applications to&nbsp;provide the&nbsp;best experience to users.</p>
				<p className='about_paragraph paragraph'>I’m a digital designer and a creative coder with knowledge of web programming languages such as HTML, CSS and JS.</p>
			</div>
			<div className='about_section about_experiences'>
				<img className='hero_icon' src={briefcase} alt='Brown case with a handle on top, and a silver colored clasp on the front'/>
				<p className='about_paragraph title'>Over the past 5 years, I&nbsp;have worked in&nbsp;digital&nbsp;marketing, video&nbsp;editing and&nbsp;communication.</p>
				<p className='about_paragraph paragraph'>L’Oréal is world's largest cosmetics group. I&nbsp;worked there as&nbsp;a&nbsp;Digital Marketing Assistant Manager.</p>
				<p className='about_paragraph paragraph'>Arte is the european culture channel. I&nbsp;worked there as&nbsp;a&nbsp;Program Assistant Manager.</p>			
			</div> */}